.datepicker_field mat-datepicker-toggle {
    float: right;
    position: relative;
    top: -37px;
    width: 12%;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle , .mat-radio-outer-circle {
    border-color: #1E6195 !important;
  }
  
.mat-radio-button.mat-accent .mat-radio-inner-circle{
    background-color: #1E6195 !important;
  }
  .ag-header-row {
    /* background-color: #00B050; */
    background-color: #1e6195;
}
.ag-header-cell-text {
  color: #ffffff;
}
.mandatory-field-color{
  color: #ff0000;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 0;
}
.mat-form-field-infix {
  border-top: 0;
  padding: 0 !important;

}
.preloader {
  z-index: 1000;
}
.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url('./images/loader.gif') 50% 50% no-repeat rgb(249, 249, 249);
  background-size: 4%;
  opacity: .8;
}
.formFieldsBg {
  background-color: #EDF1F5;
  margin-bottom: 4px;
  padding: 4px;
}
.formFieldsBg label {
  line-height: 34px;
  margin-bottom: 0;
}

.btn-new-primary:hover {
  color: #fff;
  background-color: #0a0909 !important;
  border-color: #0a0909 !important;
}

.btn:hover {
  color: #fff;
  text-decoration: none;
}

.btn-new-primary {
  color: #fff;
  background-color: #0a0909 !important;
  border-color: #0a0909 !important;
  box-shadow: 0 4px 6px rgba(15, 15, 15, 0.11), 0 1px 3px rgb(0 0 0 / 8%);
  text-transform: uppercase;
  width: 140px;
  height: 48px;
}
.btn-warning:hover {
  color: #fff;
  background-color: #1E6195 !important;
  border-color: #1E6195 !important;
}
.btn-warning {
  color: #fff;
  background-color: #1E6195;
  border-color: #1E6195;
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}

#main-wrapper[data-layout=vertical] .topbar .top-navbar .navbar-header[data-logobg=skin5], #main-wrapper[data-layout=horizontal] .topbar .top-navbar .navbar-header[data-logobg=skin5] {
  background: #1e6195;
  height: 85px;
}
.btn-primary{
  text-transform: uppercase;
}
.table th, .table td {
  padding: 0.9375rem 0.4rem;
  vertical-align: top;
  border-top: 0px solid !important;
}
.sidebar-nav ul .sidebar-item .sidebar-link.active {
  font-weight: 500;
  border-left: 3px solid #2cabe3;
  color: #00b050  !important;
}
.submitBtn {
  width: 140px;
  height: 48px;
  line-height: 48px;
  background: #051721;
  color: #ffffff;
  text-align: center;
  border: none;
}
.footer{
  background-color: #c0d3e1 !important;
}
.container-fluid {
  background: #edf1f5;
}
/* 
body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #313131;
  text-align: left;
  background-color: #edf1f5;

  font-family: Poppins;
font-size: 14px;
line-height: 16px;
color: #051721;
}
.menu-section {
  height: 68px;
  line-height: 68px;
  font-weight: bold;
  border-bottom: 1px solid #cccccc;
} */
h2 {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 25px;
}
.cr-data-hegiht{
  height: 650px;
}
.page-breadcrumb {
  background-color: #051721 !important;
}

@font-face {
  font-family: Poppins-Regular;
  src: url("./fonts/Poppins/Poppins-Regular.eot") /* EOT file for IE */
 }
 @font-face {
  font-family: Poppins-Regular;
  src: url("./fonts/Poppins/Poppins-Regular.ttf") /* TTF file for CSS3 browsers */
 }
 @font-face {
  font-family: OpenSans-Regular;
  src: url("../assets/fonts/opensans/OpenSans-Regular.eot") /* EOT file for IE */
 }
 @font-face {
  font-family: OpenSans-Regular;
  src: url("../assets/fonts/opensans/OpenSans-Regular.ttf") /* TTF file for CSS3 browsers */
 }
 .main-header-sub-header{
   font-family:  OpenSans-Regular;
 }
 #main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin6] .sidebar-nav ul .sidebar-item .sidebar-link, #main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin6] .sidebar-nav ul .sidebar-item .sidebar-link {
  color: #051721;
  opacity: 0.9;
}
.main-menu-font{
  font-weight: bold;
}
.hide-show-menu{
  display: block;
}
.hide-menu {
   margin-right: 0px !important;
}
a.sidebar-link.waves-effect.waves-dark:hover{
  background-color: #c0d3e1;;
}
/*
a.sidebar-link.waves-effect.waves-dark.active:hover{
  background-color: #c0d3e1;;
} */
.mat-select{
  font-family: Poppins-Regular;
}
.form-control {
  color: #051721 !important;
}
.btn-save{
  color: #fff;
  background-color: #1E6195;
  border-color: #1E6195;
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  width: 140px;
  height: 48px;
  text-transform: uppercase;
}
.btn-back-to-list{
  color: #fff;
  background-color: #1E6195;
  border-color: #1E6195;
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  width: 140px;
  height: 48px;
  text-transform: uppercase;
}
.datepicker {
  float: left;
  width: 92%;
}
.mat-datepicker-toggle .mat-icon-button {
  width: 36px !important;
  height: 35px !important;
  background-color: #1e6195 !important;
  margin-left: -2px !important;
  line-height: 30px !important;
  border-radius: 0 !important;
  color: #fff;
}
