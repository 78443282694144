// In this scss you can overwrite all our scss, we suggested you to add your scss into this, so whenever the update comes it will not affect your code.
@media (min-width: 768px){
    #main-wrapper[data-layout=vertical][data-sidebartype=full] .topbar .top-navbar .navbar-header {
        width: auto !important;
    }
}
#main-wrapper[data-layout=vertical][data-header-position=fixed] .page-wrapper {
    padding-top: 85px;
}
.left-sidebar {
    padding-top: 70px;
}
.page-breadcrumb {
    padding: 13px 30px;
    background-color: #0a0909;
    color: #fff;
}
.parent-copmany{
    max-height: 70px;
}
.table .thead-success th {
    color: #e9ecef;
    background-color: #04750d;
    border-color: rgba(120, 130, 140, 0.13);
}
.table .thead-success .tr-success-light th{
    background-color: #03ad11;
}
